import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'gatsby';
import * as styles from '../styles/header.module.css'

function Header() {
    return (
        <Container fluid="xxl" className={styles.appHeader}>
            <Navbar collapseOnSelect expand="md" className="py-5 px-3">
                <Navbar.Brand href="/" className={styles.brand}>Juan Carlos Fontecha</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="header-toggle"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className={styles.navigation + " ms-auto"}>
                        <Link to="/" className={styles.navLink + " mx-3"}>Home</Link>
                        <Link to="/content" className={styles.navLink + " mx-3"}>Content</Link>
                        <Link to="https://blog.jcfontecha.com" className={styles.navLink + " mx-3"}>Blog</Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
}

export default Header;