import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGithub, faInstagram, faLinkedin, faSoundcloud, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import * as styles from '../styles/socials.module.css'
import { FunctionComponent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";

interface SocialNetworkOptionAll {
    option: 'all'
    socials?: SocialNetwork[]
}

interface SocialNetworkOptionSome {
    option: 'some'
    socials: SocialNetwork[]
}

export enum SocialNetwork {
    Instagram,
    Twitter,
    Facebook,
    Github,
    LinkedIn,
    YouTube,
    Soundcloud
}

type SocialMenuItem = {
    network: SocialNetwork
    icon: IconProp
    link: string
}

export type SocialsProps = { className?: string } & (
    | SocialNetworkOptionAll
    | SocialNetworkOptionSome
);

const Socials: FunctionComponent<SocialsProps> = ({ option, className, socials }) => {

    const classIdentifier = styles.socialIcons

    const supportedItems: SocialMenuItem[] = [
        {
            network: SocialNetwork.YouTube,
            icon: faYoutube,
            link: "https://www.youtube.com/channel/UCiBI_5Kv-NGpE8FQaQgZoLQ"
        },
        {
            network: SocialNetwork.Twitter,
            icon: faTwitter,
            link: "https://www.twitter.com/jfontech"
        },
        {
            network: SocialNetwork.Instagram,
            icon: faInstagram,
            link: "http://www.instagram.com/jfontech"
        },
        {
            network: SocialNetwork.Facebook,
            icon: faFacebook,
            link: "http://www.facebook.com/jcfontecha"
        },
        {
            network: SocialNetwork.Github,
            icon: faGithub,
            link: "https://github.com/jcfontecha"
        },
        {
            network: SocialNetwork.LinkedIn,
            icon: faLinkedin,
            link: "https://www.linkedin.com/in/jcfontecha/"
        },
        {
            network: SocialNetwork.Soundcloud,
            icon: faSoundcloud,
            link: "https://soundcloud.com/jcfontecha"
        }
    ]

    function getClassName(): string {
        return `${classIdentifier} ${className}`;
    }

    function getSocialNetworksToRender(): SocialMenuItem[] {
        if (option === 'all') {
            return supportedItems;
        } else {
            const requestedMenuItems = supportedItems.filter(item => socials?.includes(item.network))
            return requestedMenuItems;
        }
    }

    return(
        <div className={getClassName()}>
            <ul>
                { getSocialNetworksToRender()?.map(item => <li><a target="_blank" rel="noreferrer" href={item.link}><FontAwesomeIcon icon={item.icon} /></a></li>) }
            </ul>
        </div>
    );
}

export default Socials;