import React from "react";
import { Col, Row } from "react-bootstrap";
import Socials from "./socials";
import * as styles from '../styles/footer.module.css'

function Footer() {
    function getCopyright(): string {
        const date = new Date();
        return `© Juan Carlos Fontecha ${date.getFullYear()}`;
    }

    return(
        <footer className="mt-auto container-fluid">
            <Row className={styles.footer}>
                <Col xxl='1' className="p-0" />
                <Col className="row my-auto p-0">
                    <Col sm='12' md='4' className="my-auto">
                        <div className={styles.thankYou + " align-middle text-center p-5"}>Thank you for visiting.</div>
                    </Col>
                    <Col sm='12' md='4' className="my-auto">
                        <Socials option='all'></Socials>
                    </Col>
                    <Col sm='12' md='4' className="my-auto">
                        <div className={styles.copyright + " align-middle text-center p-5"}>{getCopyright()}</div>
                    </Col>
                </Col>
                <Col xxl='1' className="p-0"/>
            </Row>
        </footer>
    );
}

export default Footer;